/* .container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
} */


.navbar {
  top: 0px;
  padding: 0px;
  width: 100%;
  height: 50px;
  background-color: #18191a;
  position: relative;
  z-index: 1000;
  position: fixed;
}

.logo {}

.menu-icon {
  display: none;
  font-size: 25px;
}

.nav-elements {
  color: #917FFB;
  height: 25px;
}


.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;

}

.nav-elements ul a {
  font-size: 13px;
  font-weight: 400;
  color: #f9f9f9;
  text-decoration: none;
}

.nav-elements ul a.active {
  font-weight: 500;
  position: relative;
  color: "#917FFB"
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
  color: brown
}

@media (max-width: 1200px) {
  .navbar {
    height: 100px;
  }
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }

  .navbar {
    /* position: relative; */

  }
}

@media (max-width: 1000px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .navbar {
    height: 50px;

  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 50px;
    background-color: #0e0a02;
    width: 0px;
    height: calc(100vh);
    transition: all 0.3s ease-in;
    overflow: hidden;

    /* background: transparent;
    backdrop-filter: blur(10px);  */
    background: rgba(0, 0, 0, .8);
    backdrop-filter: blur(5px);

    letter-spacing: 0.1em;

  }


  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    color: "#917FFB";

  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;

  }

  .nav-elements ul a {
    font-size: 16px;

  }
}