.Testimonial .DetailSection {
    background: linear-gradient(#0e161b,#0f1215 );
    box-shadow: 0 4px 8px 0 rgba(7, 65, 121, 0.8), 0 6px 20px 0 rgba(7, 65, 121, 0.2);
}

.Testimonial .DetailSection .Name{
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
}

.Testimonial .DetailSection .Designation {
    color: #917ffb;
    font-size: 15px;
}

.Testimonial .DetailSection .Review {
    color: #b6c7cf;
    font-style:normal;
    /* font-size: 13px; */
}

.Testimonials {
    width: 100%;
    /* height: 400px; */
}